@import "../../../../styles/variables";

.aboutmainContainer {
  position: relative;

  .aboutCorner {
    position: absolute;
    left: 0;
    top: 0;
    right: auto;
    z-index: -1;
  }
}
.aboutContainer {
  max-width: 1370px;
  width: 100%;
  margin: auto;
  padding: 15px;

  .subContainer {
    text-align: center;
    color: $black;
    max-width: 1024px;
    margin: auto;
    margin-bottom: 40px;

    .aboutHeading {
      font-weight: 700;
      font-size: 40px;
      line-height: 65px;
      margin-bottom: 12px;
      text-transform: uppercase;
    }
    .aboutDisc {
      font-size: 16px;
      line-height: 30.5px;
    }
  }

  .aboutRoomContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 40px;

    .aboutRoomBox {
      width: 100%;
      height: 550px;
      border-radius: 20px;
      overflow: hidden;
    }

    .aboutRoomBox2 {
      :nth-child(1) {
        width: 100%;
        height: 320px;
        border-radius: 20px;
        overflow: hidden;
      }
      .aboutRoomDisc {
        text-align: center;
        line-height: 30.5px;
        font-size: 16px;
        padding: 16px;
        margin-top: 10px;
        color: $primary;
        font-weight: 500;
      }
    }
  }
}

@media screen and (max-width: 643px) {
  .aboutmainContainer {
    .aboutCorner {
      height: 700px;
    }
  }
  .aboutContainer {
    .subContainer {
      .aboutHeading {
        font-size: 24px;
      }
      .aboutDisc {
        line-height: 28px;
      }
    }

    .aboutRoomContainer {
      gap: 20px;

      .aboutRoomBox {
        height: 400px;
      }

      .aboutRoomBox2 {
        :nth-child(1) {
          height: 280px;
        }
        .aboutRoomDisc {
          line-height: 28px;
          padding: 5px;
        }
      }
    }
  }
}
